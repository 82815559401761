
import useFixedNav from '~/hooks/useFixedNav'
import Nuxt2Svg from '~/static/icon/nuxt2.svg'

export default {
  components: {
    Nuxt2Svg
  },
  setup() {
    return { ...useFixedNav() }
  }
}
