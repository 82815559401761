
import { computed } from '@nuxtjs/composition-api'

export default {
  props: {
    post: {
      type: Object,
      default: function () {
        return {}
      }
    }
  },
  setup(props) {
    const filterTags = computed(() => {
      return props.post.fields.category === 'Scrap'
        ? ['Scrap'].concat(props.post.fields.tags!)
        : props.post.fields.tags
    })
    return { filterTags }
  }
}
