
import NotionSvg from '~/static/icon/notion.svg'

import { CONTACT_URL } from '~/utils/constants'

export default {
  components: {
    NotionSvg
  },
  data() {
    return { contactUrl: CONTACT_URL }
  }
}
