
import { computed } from '@nuxtjs/composition-api'

export default {
  props: {
    title: {
      type: String,
      default: ''
    },
    errorType: {
      type: String,
      default: 'normal'
    }
  },
  setup(props) {
    const backgroundColor = computed(() => {
      if (props.errorType === 'error') return 'error'
      if (props.errorType === 'warning') return 'warning'
      return 'normal'
    })
    const years = computed(() => {
      if (props.errorType === 'error') return 3
      if (props.errorType === 'warning') return 1
      return ''
    })
    return { backgroundColor, years }
  }
}
