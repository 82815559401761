
import { useStringWidth } from '~/hooks/useStringWidth'

export default {
  props: {
    body: {
      type: String,
      default: ''
    }
  },
  setup(props) {
    return { useStringWidth }
  }
}
