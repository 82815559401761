
export default {
  props: {
    tags: {
      type: Array,
      default: function () {
        return []
      }
    }
  }
}
