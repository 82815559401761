
import useCalendar from '~/hooks/useCalendar'

import BakenekoSvg from '~/static/icon/bakeneko.svg'

const WEEKDAY_LIST = ['日', '月', '火', '水', '木', '金', '土']

export default {
  components: {
    BakenekoSvg
  },
  props: {
    items: {
      type: Array,
      default: function () {
        return []
      }
    },
    ym: {
      type: String,
      required: true
    }
  },
  setup(props) {
    const weekdays = WEEKDAY_LIST
    return { weekdays, ...useCalendar(props) }
  }
}
