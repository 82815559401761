
import Vue from 'vue'

Vue.config.ignoredElements = ['adsbygoogle']

export default Vue.extend({
  props: {
    adSlot: {
      type: String,
      default: ''
    },
    adFormat: {
      type: String,
      default: ''
    },
    adLayout: {
      type: String,
      default: ''
    },
    adStyle: {
      type: Object,
      default: null
    }
  }
})
